<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Veranstaltung bearbeiten">
      <event-form
        :loading="loading"
        :event="event"
        :form-submitted="saveEvent"
        :submit-label="submitLabel"
        mode="edit"
      ></event-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventForm from "@/components/forms/EventForm"
import OrganizationEventsRepository from "@/repositories/organization/events_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    TheBreadcrumbs,
    PageContent,
    EventForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      event: {
        dates_attributes: []
      }
    }
  },
  computed: {
    submitLabel() {
      return this.event.status === "needs_edit"
        ? "Überarbeitung abschicken"
        : "Änderungen speichern"
    }
  },
  async created() {
    try {
      this.event = await OrganizationEventsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveEvent(event) {
      await OrganizationEventsRepository.update(event.id, event)
      try {
        await this.$router.push({
          name: "EventsPage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    }
  }
}
</script>

<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Veranstaltung bearbeiten">
      <event-form
        :loading="loading"
        :event="event"
        :form-submitted="saveEvent"
        mode="edit"
      ></event-form>
    </page-content>
    <portal to="fixed-footer-right">
      <el-button-group>
        <el-button
          icon="fal fa-thumbs-up"
          size="small"
          :disabled="
            event.status === 'approved' || event.status === 'needs_edit'
          "
          @click="approve"
        >
          Freischalten
        </el-button>
        <el-button
          :disabled="
            event.status === 'needs_edit' || event.status === 'rejected'
          "
          icon="fal fa-user-edit"
          size="small"
          @click="askForEdit"
        >
          Um Überarbeitung bitten
        </el-button>
        <el-button
          :disabled="
            event.status === 'needs_edit' || event.status === 'rejected'
          "
          icon="fal fa-thumbs-down"
          size="small"
          @click="reject"
        >
          Ablehnen
        </el-button>
      </el-button-group>
    </portal>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventForm from "@/components/forms/EventForm"
import EventsRepository from "@/repositories/events_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    TheBreadcrumbs,
    PageContent,
    EventForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      event: {
        dates_attributes: []
      }
    }
  },
  async created() {
    try {
      this.event = await EventsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveEvent(event) {
      await EventsRepository.update(event.id, event)
      try {
        await this.$router.push({
          name: "EventsPage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    },
    async reject() {
      this.$router.push({
        name: "RejectEventPage",
        params: { id: this.$route.params.id },
        query: {
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    async askForEdit() {
      this.$router.push({
        name: "AskForEventEditPage",
        params: { id: this.$route.params.id },
        query: {
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    async approve() {
      try {
        await EventsRepository.approve(this.$route.params.id)
        this.$message({
          message: "Veranstaltung wurde freigeschaltet.",
          type: "success",
          showClose: true
        })

        try {
          await this.$router.push({
            name: "EventsPage",
            query: { allow_redirect: true }
          })
        } catch {
          //
        }
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }
}
</script>

<template>
  <component :is="page"></component>
</template>

<script>
import { mapGetters } from "vuex"
import RegionEditEventPage from "@/views/events/RegionEditEventPage"
import OrganizationEditEventPage from "@/views/events/OrganizationEditEventPage"

export default {
  metaInfo: {
    title: "Veranstaltung bearbeiten"
  },
  components: {
    RegionEditEventPage,
    OrganizationEditEventPage
  },
  computed: {
    page() {
      if (this.isSuperAdmin || this.isRegionUser) {
        return "region-edit-event-page"
      } else {
        return "organization-edit-event-page"
      }
    },
    ...mapGetters("auth", [
      "isSuperAdmin",
      "isOrganizationUser",
      "isRegionUser"
    ])
  }
}
</script>
